#hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  font-weight: 400;
  color: $light-grey;
  padding: 0;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url('assets/madeira-min.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;

  // Set position sticky so the jumbotron stays fixed while you scroll.
  // position: sticky;

  // If you want to set a background image on the hero section, uncomment these with your custom image

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  #hero-container {
    background-color: rgba(0, 0, 0, .7);
    height: 100%;
    min-height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .hero-title {
    position: relative;
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    text-align: left;
    z-index: 1000;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3.5rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.8rem;
    }
  }

  .hero-cta {
    z-index: 1000;
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      z-index: 1000;
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }

  .hero-language {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px;
  }

  #language-chooser {
    font-size: 2.8rem;
    font-weight: 700;
    background-image: linear-gradient(135deg, $primary-color 0%, $secondary-color 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #0000;

    &:hover {
      opacity: 60%;
    }
  }
}
