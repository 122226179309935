// Section
section {
  padding: 5rem 0rem;

  @include respond(phone) {
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.project {
  display: flex;
}

.project>div {
  display: flex;
  align-items: center;
}
